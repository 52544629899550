
export default {
  name: 'reconfirm-code-dialog',
  props: {
    show: Boolean,
    licensePlateNumber: String,
    reconfirmCode: String,
    onDialogClose: Function,
    defaultHeight: Number
  },
  data () {
    return {
      dialogHeight: this.$props.defaultHeight || 300
    }
  },
  mounted () {
    setTimeout(() => {
      this.onContainerResize.bind(this)
    }, 800)
  },
  methods: {
    closeDialog () {
      if (this.$props.onDialogClose) {
        this.$props.onDialogClose()
      }
    },
    onContainerResize () {
      if (this.$refs) {
        this.dialogHeight = Math.round(window.innerHeight * 0.85)
      }
    }
  },
  beforeDestroy () {
  }
}
