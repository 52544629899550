<template>
  <v-card class="elevation-12" style="width: 100%">
    <v-form @submit.prevent="loginForm">
      <div v-if="!disableSignIn && formState === 'signIn'">
        <v-card-text>
          <v-layout v-if="!disableEmail && !disableMobile" justify-center class="mb-2">
            <v-btn-toggle v-model="loginType" @change="setLoginType(loginType)">
              <v-btn title="Login with a mobile phone number" small text value="mobile">
                <span>Mobile phone login</span>
              </v-btn>
              <v-btn title="Login with an email address" small text value="email">
                <span>Email login</span>
              </v-btn>
            </v-btn-toggle>
          </v-layout>
          <div v-if="loginType === 'email'">
            <v-text-field v-model="email" :rules="emailRules" validate-on-blur prepend-icon="fal fa-user" name="email" label="Email address" type="email"></v-text-field>
          </div>
          <div v-if="loginType === 'mobile'">
            <phone-number-field v-model="mobile" v-on:input="onPhoneNumberChange" v-bind="phoneNumberFieldProps" :child-props="{ label: 'Mobile number' }" />
          </div>
          <v-text-field id="password" v-model="password" :rules="passwordRules" validate-on-blur prepend-icon="fal fa-lock-alt" name="password" label="Password" type="password"></v-text-field>

          <div class="my-2" v-if="dataErrors.length || (errors && errors.length)">
            <p class="strong">Error(s) occurred:</p>
            <v-alert :value="true" type="error">
              <p :key="key" v-for="(error, key) in getFormErrors">{{ error }}</p>
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between align-center">
          <v-btn text v-show="formState" @click="cancel()" color="warning">Cancel</v-btn>
          <v-btn text v-if="!disableForgotPassword" @click="formState = 'forgotPassword'" color="primary">Forgot Password</v-btn>
          <v-btn type="submit" color="primary">Login</v-btn>
        </v-card-actions>
      </div>
      <div v-if="!disableForgotPassword && formState === 'forgotPassword'">
        <v-card-text>
          <h3 align="center" class="mb-2">Forgot Password</h3>
          <v-layout v-if="!disableEmail && !disableMobile" justify-center class="mb-2">
            <v-btn-toggle v-model="loginType" @change="setLoginType(loginType)">
              <v-btn title="Send a token link to the mobile number registered" small text value="mobile">
                <span>Reset by mobile phone</span>
              </v-btn>
              <v-btn title="Send a token link to a registered email address" small text value="email">
                <span>Reset by email</span>
              </v-btn>
            </v-btn-toggle>
          </v-layout>
          <v-text-field v-if="loginType === 'email'" v-model="email" :rules="emailRules" prepend-icon="fal fa-user" name="email" label="Email address" type="email"></v-text-field>
          <phone-number-field v-if="loginType === 'mobile'" v-model="mobile" v-bind="phoneNumberFieldProps" :child-props="{ label: 'Mobile number' }" />

          <div class="my-2" v-if="dataErrors.length || (errors && errors.length)">
            <p class="strong">Error(s) occurred:</p>
            <v-alert :value="true" type="error">
              <p :key="key" v-for="(error, key) in getFormErrors">{{ error }}</p>
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="cancelForgotPassword()" color="warning">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="primary">Submit</v-btn>
        </v-card-actions>
      </div>
      <div v-if="formState === 'sent'">
        <v-card-text>
          <h3><v-icon v-html="'fal fa-check-circle'" color="success"></v-icon> &nbsp; Password reset request successfully sent</h3>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-center>
            <v-btn @click="backToForm()" color="primary">Ok</v-btn>
          </v-layout>
        </v-card-actions>
      </div>
    </v-form>
  </v-card>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

<script>
import src from './src'
export default src
</script>
