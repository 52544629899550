import { D_GET_VEHICLE_DATA_BY_ID } from '../../utilities/action-types'

export default {
  props: {
    locale: String,
    transportationProfileId: String,
    pageHeightOffset: Number,
    onDetectComplete: Function
  },
  data () {
    return {
      errorOccurred: '',
      alertContainerMaxHeight: '100px',
      multipleResultsFound: false,
      enableScan: false,
      checkingForVehicle: false
    }
  },
  mounted () {
    setTimeout(() => {
      this.onContainerResize.bind(this)
    }, 800)
    if (this.$props.transportationProfileId) {
      this.getVehicleData(this.$props.transportationProfileId)
    } else {
      this.enableScan = true
    }
  },
  beforeDestroy () {
  },
  methods: {
    onScanDataReturned ({ busId, transportationProfileId }) {
      this.getVehicleData(busId || transportationProfileId)
    },
    getVehicleData (transportationProfileId) {
      this.errorOccurred = ''
      this.checkingForVehicle = true
      if (transportationProfileId && transportationProfileId.trim()) {
        this.$store.dispatch(D_GET_VEHICLE_DATA_BY_ID, encodeURIComponent(transportationProfileId)).then((results) => {
          this.checkingForVehicle = false
          // console.log('result', results)
          const result = results && results.length ? results[0] : results
          if (result) {
            if (result && result.error && result.error.statusCode >= 400) {
              return Promise.reject(result.error)
            } else if (result && (!result.profileid)) { //  || !result.lat || !result.long
              return Promise.reject(new Error('No appropriate transportation data was found'))
            }

            result.isSmartTransitBus = result.transportType.indexOf('smarttransit') > -1

            this.$props.onDetectComplete(result)
          } else {
            return Promise.reject(new Error('No transportation data was returned'))
          }
        }).catch((err) => {
          // console.log('error occurred')
          this.checkingForVehicle = false
          this.errorOccurred = `Error occurred: ${err && err.error ? err.error.message.replace(/\n/gm, '<br />') : (err && err.message ? err.message.replace(/\n/gm, '<br />') : JSON.stringify(err))}`
        })
      } else {
        this.errorOccurred = 'Error occurred: Please provide a bus ID'
      }
    },
    loadAutoScan () {
      this.errorOccurred = ''
      this.enableScan = true
    },
    onContainerResize () {
      if (this.$refs && (this.$refs.tryAgainButton || this.$refs.scanButton)) {
        if (this.$refs.tryAgainButton) {
          this.alertContainerMaxHeight = (window.innerHeight - this.$props.pageHeightOffset - this.$refs.tryAgainButton.$el.clientHeight) + 'px'
        } else {
          this.alertContainerMaxHeight = (window.innerHeight - this.$props.pageHeightOffset - this.$refs.scanButton.$el.clientHeight) + 'px'
        }
      }
    }
  }
}
