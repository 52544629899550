import store from '../store'
import { C_ADD_SITE_ALERT } from './mutation-types'

const DATE_FORMAT = 'MMM D Y'
const TIME_FORMAT = 'hh:mma' //

function addAlert ({ message, type, timeout, transient, id, isModal }) {
  const parsedMessage = (message && typeof message === 'object') ? (message.error && message.error.message ? message.error.message : (message.message ? message.message : JSON.stringify(message))) : message

  if (isModal) {
    alert(parsedMessage)
  } else {
    const parsedId = id || 'alert' + Date.now() + (Math.random() + '').split('.')[1]

    store.commit(C_ADD_SITE_ALERT, {
      id: parsedId,
      timeout,
      message: parsedMessage,
      type,
      transient: transient || type === 'success'
    })

    return parsedId
  }
}

function getDefaultPhonePrefix (user) {
  let countryCode

  if (user && user.passengerProfile) {
    switch (user.passengerProfile.currentCountryIdSet) {
      case 'GHA':
        countryCode = '+233'
        break
    }
  }

  if (user && !countryCode) {
    countryCode = '+1'
  }

  return countryCode
}

const dateFormatString = DATE_FORMAT
const timeFormatString = TIME_FORMAT

export {
  addAlert,
  dateFormatString,
  timeFormatString,
  getDefaultPhonePrefix
}
