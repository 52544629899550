<template>
    <div>
      <div v-if="!manualScan">
        <div class="d-flex flex-grow-1 justify-center" v-resize="onContainerResize">
          <div :style="{ height: qrCodeReaderError ? '100%' : webcamContainerHeight, width: webcamContainerWidth }">
            <qrcode-stream v-if="!qrCodeReaderError" @decode="onQrCodeDecode" @init="onQrCodeReaderInit">
                <div v-if="isQrCodeReaderLoading" class="d-flex flex-column align-center justify-center">
                    <v-alert
                        dense
                        type="info"
                        icon="fas fa-info-circle"
                        class="d-flex flex-grow-1 mb-5">
                        Please give permission to access your device camera and start scanning for a QR code.<br />
                        If you don't get a prompt in Google Chrome, please try these <a class="white--text" target="_blank" href="https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DAndroid&hl=en">steps</a>.
                    </v-alert>
                    <v-progress-circular
                        :size="35"
                        :width="3"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </div>
            </qrcode-stream>
            <div v-if="qrCodeReaderError" :style="{ height: '100%', overflowY: 'auto' }" class="ma-5 d-flex flex-column">
                <div class="d-flex flex-grow-1">
                    <v-alert
                      dense
                      type="warning"
                      icon="fas fa-exclamation-triangle">
                      <div v-html="qrCodeReaderError"></div>
                    </v-alert>
                </div>
                <div class="mb-5">
                    <v-btn block small @click="qrCodeReaderError = ''">Try again</v-btn>
                </div>
            </div>
          </div>
        </div>
        <div id="bottomSection" ref="bottomSection" class="d-flex flex-column align-stretch pa-5">
            <div v-show="!qrCodeReaderError">
                <v-alert dense type="info" icon="fa fa-info-circle">
                    Look for the QR code in the bus and let your camera see it in the window above
                </v-alert>
            </div>
            <div>
                <v-btn block @click="loadManualEntry()">
                    <v-icon color="primary" left small>fa fa-keyboard</v-icon>
                    Type in bus ID instead
                </v-btn>
            </div>
        </div>
      </div>
      <div class="d-flex flex-column align-stretch" v-if="manualScan">
        <v-text-field
            label="Enter bus id"
            v-model="busId"
            autofocus
            :rules="[v => !v || v.length >= 4 || 'Min 4 characters']"
            outlined>
        </v-text-field>
        <v-btn class="mb-5" block tile color="primary" @click="submitBusId()">
          Submit
        </v-btn>
        <v-btn class="mb-5" block @click="loadAutoScan()">
          <v-icon left small color="primary">fa fa-video</v-icon>
          <small>Scan QR Code with your camera instead</small>
        </v-btn>
        <v-alert dense type="info" icon="fa fa-info-circle">
          The bus ID is below the QR code.<br />
          Just like in the picture below.
        </v-alert>
        <div class="mb-5">
          <img src="../../assets/images/scan-qr-code-example.png" style="width: 100%" alt="Example scan picture" />
        </div>
      </div>
    </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
import src from './src'
export default src
</script>
