import { QrcodeStream } from 'vue-qrcode-reader'
import detectVehicle from './detect-vehicle/template'
import scanVehicle from './scan-vehicle/template'
import processPayment from './process-payment/template'
import reconfirmCodeDialog from './reconfirm-code-dialog/template'
import loadTicketOrGift from './load-ticket-or-gift/template'
import pendingLoader from './pending-loader/template'
import { phoneNumberField } from '@smarttransit/common-client'

export default {
  'detect-vehicle': detectVehicle,
  'scan-vehicle': scanVehicle,
  'process-payment': processPayment,
  'reconfirm-code-dialog': reconfirmCodeDialog,
  'load-ticket-or-gift': loadTicketOrGift,
  'pending-loader': pendingLoader,
  phoneNumberField,
  QrcodeStream
}
