import _ from 'lodash'

import IMask from 'imask/esm/imask'
import 'imask/esm/masked/pipe'
import 'imask/esm/masked/pattern'

const _generateKeywordsQueryForOr = function (keywordsString, fields) {
  let queries = []
  let keywords = keywordsString.trim().replace(/[\s]{2,}/g, ' ').split(' ')
  fields.forEach((field, index) => {
    if (keywords.length === 1) {
      queries.push({ [field]: { ilike: `%${keywords[0]}%` } })
    } else if (keywords.length > 1) {
      queries.push({ and: [] })
      keywords.forEach((keyword) => {
        queries[index].and.push({ [field]: { ilike: `%${keyword}%` } })
      })
    }
  })
  return queries.length ? queries : undefined
}

const _addParamsToLocation = function (params, currentRoute, lastParams) {
  const mergedParams = Object.assign(currentRoute.query || {}, lastParams || {}, params)

  const stringifiedParams = _.compact(Object.keys(mergedParams).map(key => {
    return _.isNil(mergedParams[key]) ? '' : (Array.isArray(mergedParams[key]) ? mergedParams[key].map((o) => (`${encodeURIComponent(key)}=${encodeURIComponent(o)}`)).join('&') : `${encodeURIComponent(key)}=${encodeURIComponent(mergedParams[key])}`)
  })).join('&')

  // console.log('pushState modification: ', { currentRouteQuery: currentRoute.query, lastParams, params, mergedParams, stringifiedParams })
  window.history.pushState(
    {},
    null,
    currentRoute.path + (stringifiedParams ? '?' : '') + stringifiedParams + (window.location.hash && window.location.hash !== '#' ? window.location.hash : '')
  )

  return mergedParams
}

const _getPhoneNumberMask = function (phoneNumberWithCountryCode, countryCodes) {
  let parsedPhoneNumber = (phoneNumberWithCountryCode || '')
  let mask = '### ### ####'

  if (parsedPhoneNumber && parsedPhoneNumber.indexOf('+') > -1) {
    countryCodes.forEach((item) => {
      if (parsedPhoneNumber && parsedPhoneNumber.indexOf(item.code) === 0) {
        parsedPhoneNumber = parsedPhoneNumber.replace(item.code, '')

        switch (item.code) {
          case '+1':
            // eslint-disable-next-line no-useless-escape
            mask = '\(###\) ###\-####'
            break
          case '+44':
          case '+233':
            mask = '## ### ####'
            break
        }
      }
    })
  }

  return mask
}

const usPhoneNumberMask = IMask.createPipe({
  mask: '(000) 000-0000',
  scale: 2,
  thousandsSeparator: ' ',
  normalizeZeros: true,
  padFractionalZeros: true
})

const intlPhoneNumberMask = IMask.createPipe({
  mask: '000 000 0000',
  scale: 2,
  thousandsSeparator: ' ',
  normalizeZeros: true,
  padFractionalZeros: true
})

const ukPhoneNumberMask = IMask.createPipe({
  mask: '00 000 0000',
  scale: 2,
  thousandsSeparator: ' ',
  normalizeZeros: true,
  padFractionalZeros: true
})

function _maskPhoneNumber (phoneNumberWithCountryCode, countryCodes, excludePrefix) {
  let parsedPhoneNumber = (phoneNumberWithCountryCode || '')
  let maskedPhoneNumber = ''

  if (parsedPhoneNumber && parsedPhoneNumber.indexOf('+') > -1) {
    countryCodes.forEach((item) => {
      if (parsedPhoneNumber && parsedPhoneNumber.indexOf(item.code) === 0) {
        parsedPhoneNumber = parsedPhoneNumber.replace(item.code, '')

        switch (item.code) {
          case '+1':
            maskedPhoneNumber = (excludePrefix ? '' : item.code + ' ') + usPhoneNumberMask(parsedPhoneNumber)
            break
          case '+44':
          case '+233':
            maskedPhoneNumber = (excludePrefix ? '' : item.code + ' ') + ukPhoneNumberMask(parsedPhoneNumber)
            break
          default:
            maskedPhoneNumber = (excludePrefix ? '' : item.code + ' ') + intlPhoneNumberMask(parsedPhoneNumber)
        }
      }
    })
  } else {
    maskedPhoneNumber = parsedPhoneNumber
  }

  return maskedPhoneNumber
}

function _extractPhonePrefixFromNumber (phoneNumber, countryCodes) {
  let phonePrefix = ''

  for (let i = 0; i < countryCodes.length; i++) {
    const item = countryCodes[i]

    if (phoneNumber && phoneNumber.indexOf(item.code) === 0) {
      if (item.code === '+1' && phoneNumber.length === 12) {
        phonePrefix = '+1'
      } else if (item.code === '+44' && phoneNumber.length === 12) {
        phonePrefix = '+44'
      } else if (item.code === '+233' && phoneNumber.length === 13) {
        phonePrefix = '+233'
      } else {
        phonePrefix = item.code
      }

      return phonePrefix
    }
  }

  return phonePrefix
}

/**
 * @param {object} params
 * @param {object} params.paramsObj
 * @param {object} params.route
 * @param {object|undefined} [params.lastParams=undefined]
 * @returns {object}
 * @private
 */
function _updateQueryParams ({ paramsObj, route, lastParams }) {
  const updatedParamsObj = {}

  for (const key in paramsObj) {
    if (paramsObj[key] !== undefined && paramsObj[key] !== null && paramsObj[key] !== '') {
      updatedParamsObj[key] = paramsObj[key]
    } else if (lastParams?.[key]) {
      lastParams[key] = ''
    }
  }

  return _addParamsToLocation({ ...updatedParamsObj }, route, lastParams)
}

function _extractBaseNameFromFilePath (path) {
  const filename = String(path).split('/')
  return filename.length ? filename[filename.length - 1] : ''
}

export const generateKeywordsQueryForOr = _generateKeywordsQueryForOr
export const addParamsToLocation = _addParamsToLocation
export const updateQueryParams = _updateQueryParams
export const getPhoneNumberMask = _getPhoneNumberMask
export const maskPhoneNumber = _maskPhoneNumber
export const extractPhonePrefixFromNumber = _extractPhonePrefixFromNumber
export const extractBaseNameFromFilePath = _extractBaseNameFromFilePath
