// import 'vue-qrcode-reader/dist/vue-qrcode-reader.css'

export default {
  props: {
    locale: String,
    pageHeightOffset: Number,
    onScanDataReturned: Function, // returns { busId, transportationProfileId, scannedLocale }
    initWithManualScan: Boolean
  },
  data () {
    return {
      webcamContainerHeight: '100px',
      webcamContainerWidth: '100px',
      isQrCodeReaderLoading: false,
      qrCodeReaderError: '',
      manualScan: false,
      busId: '',
      transportationProfileId: '',
      scannedLocale: ''
    }
  },
  mounted () {
    setTimeout(() => {
      this.onContainerResize.bind(this)
    }, 800)
    if (this.$props.initWithManualScan) {
      this.manualScan = true
    }
  },
  methods: {
    loadManualEntry () {
      this.manualScan = true
    },
    loadAutoScan () {
      this.manualScan = false
    },
    submitBusId () {
      if (this.busId || this.transportationProfileId) {
        this.$props.onScanDataReturned({ busId: this.busId, transportationProfileId: this.transportationProfileId, scannedLocale: this.scannedLocale })
      } else {
        alert('No Bus Id found to submit')
      }
    },
    onContainerResize () {
      if (this.$refs) {
        this.webcamContainerWidth = (window.innerWidth > 768 ? 768 : window.innerWidth) + 'px'
        this.webcamContainerHeight = (window.innerHeight - this.$props.pageHeightOffset - this.$refs.bottomSection.clientHeight - 50) + 'px'
      }
    },
    onQrCodeDecode (decodedString) {
      const segments = decodedString ? decodedString.split('/') : []
      if (segments.length > 2 && segments[segments.length - 2].toLowerCase() === 'pay' && segments[segments.length - 1]) {
        this.transportationProfileId = segments[segments.length - 1]
        this.scannedLocale = segments[segments.length - 3]
        this.submitBusId()
      } else {
        alert('There was a problem retrieving the correct information from the QR code: ' + decodedString)
      }
    },
    async onQrCodeReaderInit (promise) {
      // show loading indicator
      this.isQrCodeReaderLoading = true
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          // user denied camera access permission
          this.qrCodeReaderError = `Make sure you are accessing this page at <a class="white--text" href="${location.href.replace('http://', 'https://')}">${location.href.replace('http://', 'https://')}</a>, or check permission access to your device camera.<br />If in Google Chrome, please try these <a class="white--text" target="_blank" href="https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DAndroid&hl=en">steps</a>.`
        } else if (error.name === 'NotFoundError') {
          // no suitable camera device installed
          this.qrCodeReaderError = `Could not find a suitable camera on this device, please try <a class="white--text" href="${this.$router.resolve({ name: 'scan-manual', params: { locale: this.$props.locale } }).href}">typing in the bus ID</a>`
        } else if (error.name === 'NotSupportedError') {
          // page is not served over HTTPS (or localhost)
          this.qrCodeReaderError = 'Please view this page over HTTPS to access the device camera'
        } else if (error.name === 'NotReadableError') {
          // maybe camera is already in use
          this.qrCodeReaderError = 'The camera seems to already be initialized'
        } else if (error.name === 'OverconstrainedError') {
          // did you requested the front camera although there is none?
          this.qrCodeReaderError = 'No frontend camera found to use'
        } else if (error.name === 'StreamApiNotSupportedError') {
          // browser seems to be lacking features
          this.qrCodeReaderError = `This browser does not seem to support camera access, please try <a class="white--text" href="${this.$router.resolve({ name: 'scan-manual', params: { locale: this.$props.locale } }).href}">typing in the bus ID</a>`
        }
      } finally {
        this.isQrCodeReaderLoading = false
      }
    }
  }
}
