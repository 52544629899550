
export const D_GET_FROM_CACHE = 'dispatchGetCache'
export const D_SET_TO_CACHE = 'dispatchSetCache'
export const D_GET_FROM_BROWSER_SETTING = 'dispatchGetBrowserSetting'

export const D_ADD_SITE_ALERT = 'dispatchSiteAlert'

export const D_GET_VEHICLE_DATA_BY_ID = 'dispatchGetVehicleDataById'

export const D_PAY_FARE = 'dispatchPayFare'

export const D_CHECK_PAYMENT = 'dispatchCheckPayment'

export const D_GET_TICKET = 'dispatchGetTicket'

export const D_RECONFIRM_TRANSPORTATION = 'dispatchReconfirmTransportation'

export const D_GET_MAP_REGIONS = 'dispatchGetMapRegions'
