<template>
  <div>
    <div class="d-flex justify-center">
      <v-btn tile block @click="loadOptions()">Send Gift to a Passenger</v-btn>
    </div>
    <v-dialog v-model="optionsDialog" max-width="400px">
      <v-card>
        <v-card-title class="mb-10">
          <div class="close-btn-container" style="width: 100%">
            <v-btn icon @click="optionsDialog = false" class="close-btn">
              <v-icon>far fa-times</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="m2-5 mb-3">
            <v-btn tile block :to="{ name: 'send-gift', params: { locale: $props.locale } }" class="primary">
              <v-icon left class="mr-4">fas fa-hands-usd</v-icon> Send a gift
            </v-btn>
          </div>
          <v-alert dense outlined class="mb-5" color="blue">
            <small>Send a gift credit to a friend or receive one! Credit can be used by SmartTransit registered accounts to purchase tickets</small>
          </v-alert>
<!--          <div class="mb-3">-->
<!--            <v-btn tile block :to="{ name: 'send-gifted-ticket', params: { locale: $props.locale } }" class="primary">-->
<!--              <v-icon left class="mr-4">fas fa-hand-receiving</v-icon> <small>Send a gifted ticket</small>-->
<!--            </v-btn>-->
<!--          </div>-->
<!--          <v-alert dense outlined class="mb-5" color="blue">-->
<!--            <small>Send a ticket to a friend or receive one!<br />-->
<!--              The sender can set the bus on the recipient's behalf, or the recipient can choose any bus they want</small>-->
<!--          </v-alert>-->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
</style>

<script>
import src from './src'
export default src
</script>
