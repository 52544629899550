import axios from 'axios'
import _ from 'lodash'
// import store from '../store'

const _apiRequests = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  timeout: 15000,
  headers: {}
})

_apiRequests.postFormData = function (url, data, config = {}) {
  const bodyFormData = new FormData()

  _.forEach(data, (value, key) => {
    value = value && typeof value === 'object' ? JSON.stringify(value) : value
    bodyFormData.set(key, encodeURIComponent(value))
  })

  config.headers = config.headers || {}
  config.headers['Content-Type'] = `multipart/form-data; boundary=${bodyFormData._boundary}`
  return _apiRequests.post(url, bodyFormData, config)
}

_apiRequests.interceptors.request.use(function (config) {
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

_apiRequests.interceptors.response.use(function (config) {
  return config
}, function (error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // Do something with request error
    // const errorObj = _.get(error.response, 'data.error')
    // if (errorObj) {
    //   store.commit(C_ADD_SITE_ALERT, {
    //     message: `Error ${errorObj.status}: ${errorObj.message || 'Unknown'}`
    //   })
    // }
    return Promise.reject(error.response.data)
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // console.log('No response', error.request)
    const err = new Error('No response from server')
    err.status = error.request.status
    return Promise.reject(err)
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log('Request not completed', error.message)
  }
})

export const apiRequests = _apiRequests
