<template>
    <div class="d-flex flex-column align-stretch justify-center">
      <div v-if="checkingForVehicle">
        <div class="d-flex justify-center my-5">
            <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
            />
        </div>
        <div class="text-center mb-5">
            Checking for vehicle...
        </div>
      </div>
      <div class="mb-5" v-if="!errorOccurred && enableScan">
        <scan-vehicle :locale="locale" :page-height-offset="pageHeightOffset" :on-scan-data-returned="onScanDataReturned" />
      </div>
      <div v-if="!errorOccurred && !enableScan">
        <v-btn block tile color="primary" ref="scanButton" @click="loadAutoScan()" v-resize="onContainerResize">
          Scan/enter bus ID
        </v-btn>
      </div>
      <div v-if="errorOccurred">
        <v-alert
            dense
            type="warning"
            icon="fas fa-exclamation-triangle"
            :style="{ maxHeight: alertContainerMaxHeight, overflowY: 'auto' }">
            <div v-html="errorOccurred"></div>
        </v-alert>
        <v-btn block tile id="tryAgainButton" ref="tryAgainButton" color="primary" @click="loadAutoScan()" v-resize="onContainerResize">
           Try scanning/entering again
        </v-btn>
      </div>
    </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
import src from './src'
export default src
</script>
