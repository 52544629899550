
export default {
  props: {
    locale: String
  },
  data () {
    return {
      optionsDialog: false
    }
  },
  methods: {
    loadOptions () {
      this.optionsDialog = true
    }
  }
}
