import _ from 'lodash'
import moment from 'moment-timezone'
import mtnMoneyLogo from '../../assets/images/mtn-money-logo.png'
import airtelTigoMoneyLogo from '../../assets/images/airteltigo-money-logo.png'
import vodaphoneMoneyLogo from '../../assets/images/vodaphone-money-logo.jpg'
import { D_GET_FROM_CACHE, D_PAY_FARE, D_CHECK_PAYMENT, D_SET_TO_CACHE } from '../../utilities/action-types'

const CURRENT_USER_EXPIRY_SECS = parseInt(process.env.VUE_APP_USER_EXPIRY_SECS) // expire after 1 month
const PENDING_PAYMENT_TTL_MSECS = 50000
const COUNTRY_CODES = {
  GHA: '+233'
}

export default {
  name: 'process-payment',
  props: {
    locale: String,
    transportationProfile: Object,
    onTicketReceived: Function
  },
  data () {
    return {
      currentCurrency: '', // ₵',
      currentCurrencyCents: '',
      errorOccurred: '',
      currentCountryCode: '',
      secretPin: '',
      authenticationType: null,
      profileData: null,
      dollars: null,
      cents: null,
      paying: false,
      loadingCurrentUserData: false,
      currentUserData: null, // { mobile, dollars, cents, moneyTypeId }
      mobile: '',
      mobileMoneyProvider: null,
      mobileMoneyProviders: [
        { title: 'MTN Mobile Money', src: mtnMoneyLogo, typeId: 'mtnghana' },
        { title: 'Airtel/Tigo Money', src: airtelTigoMoneyLogo, typeId: 'atigoghana' },
        { title: 'Vodafone Cash', src: vodaphoneMoneyLogo, typeId: 'vodaghana' }
      ]
    }
  },
  mounted () {
    // transportationProfile contains:
    // profileid: (...)
    // lat: (...)
    // long: (...)
    // type: (...)
    // timestamp: (...)
    // location: (...)
    // metadata: (...)
    // licensePlateNumber: (...)
    // isRouteFree: (...)
    // countryCurrency: (...)
    if (this.$props.transportationProfile) {
      this.profileData = _.cloneDeep(this.$props.transportationProfile)

      if (this.profileData.isSmartTransitBus) {
        this.errorOccurred = 'This bus is a SmartTransit 2.0 bus, please use your beacon or smartphone (or ask the driver for further assistance)'
      } else {
        this.currentCurrency = this.$props.transportationProfile.countryCurrency.currencySymbol
        this.currentCurrencyCents = this.$props.transportationProfile.countryCurrency.currencyCentsName
        this.currentCountryCode = COUNTRY_CODES[this.$props.transportationProfile.countryCurrency.countryId] || ''
        this.profileData.dateTime = moment(this.profileData.timestamp).format('MMM D Y, hh:mma')
        this.$nextTick(() => this.initCurrentUserData())
      }
    }
  },
  beforeDestroy () {
  },
  methods: {
    errorOkClicked () {
      this.errorOccurred = ''

      if (this.profileData.isSmartTransitBus) {
        this.$router.push({ name: 'scan-auto' })
      }
    },
    payNow () {
      if (!this.dollars && !this.cents) {
        return alert('Please enter a fare amount')
      }
      if (parseInt(this.dollars) < 0) {
        return alert('Please enter an amount greater than 0')
      }
      if (parseInt(this.cents) < 0 || parseInt(this.cents) > 99) {
        return alert('Please enter a ' + this.currentCurrencyCents + ' amount between 0-99')
      }
      if (!this.mobile) {
        return alert('Please enter a mobile number')
      }
      if (this.mobile.indexOf('+') === -1) {
        return alert('Please select a country code')
      }
      if (this.authenticationType === 'unknownUser') {
        if (!this.mobileMoneyProvider) {
          return alert('Please select a mobile money provider for your number')
        }
      } else if (this.authenticationType === 'secretPin') {
        if (!this.secretPin) {
          return alert('Please enter your SmartTransit account password')
        }
      }
      const amountLabel = (this.dollars || '0') + '.' + (this.cents || '00')
      const amount = Math.floor(parseFloat(amountLabel) * 100)
      if (amount > 599) {
        return alert('Ticket purchases can only be less than ' + this.currentCurrency + '6.00')
      }
      if (confirm(`Please confirm paying ${this.currentCurrency}${amountLabel} on bus: ${this.profileData.licensePlateNumber}`)) {
        this.paying = true
        this.$store.dispatch(D_PAY_FARE, {
          phone: this.mobile,
          amount,
          moneyAccountType: this.authenticationType === 'unknownUser' ? this.mobileMoneyProvider.typeId : '',
          osVersion: navigator.userAgent,
          secretPin: this.authenticationType === 'secretPin' ? this.secretPin : '',
          transportationMetadata: {
            transportationProfileId: this.profileData.profileid,
            latitude: this.profileData.lat,
            longitude: this.profileData.long,
            originLabel: this.profileData.location,
            isLive: this.profileData.location && this.profileData.location.toLowerCase().indexOf('unknown') === -1
          }
        }).then((result) => {
          // console.log('result', result)
          this.$store.dispatch(D_SET_TO_CACHE, {
            key: 'currentUser',
            value: {
              mobile: this.mobile,
              dollars: this.dollars,
              cents: this.cents,
              moneyTypeId: this.mobileMoneyProvider ? this.mobileMoneyProvider.typeId : null,
              authenticationType: this.authenticationType
            },
            expiryInSeconds: CURRENT_USER_EXPIRY_SECS
          })
          if (result.transactionId) {
            this.checkPaymentStatus(result.transactionId, (err, statusResult) => {
              this.paying = false
              if (err) {
                this.errorOccurred = `Error completing payment: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`
              } else {
                // ticket is ready
                // console.log({ statusResult })
                this.$props.onTicketReceived(statusResult.id)
              }
            })
          } else if (result.id) {
            this.$props.onTicketReceived(result.id)
          }
        }).catch((err) => {
          this.paying = false
          this.errorOccurred = `Error occurred: ${err && err.error ? err.error.message.replace(/\n/gm, '<br />') : (err && err.message ? err.message.replace(/\n/gm, '<br />') : JSON.stringify(err))}`
        })
      }
    },
    checkPaymentStatus (transactionId, callback, ttl = 0) {
      setTimeout(() => {
        if (ttl > PENDING_PAYMENT_TTL_MSECS) {
          callback(new Error('The payment request timed out, you can try again.'))
        } else {
          this.$store.dispatch(D_CHECK_PAYMENT, transactionId).then((result) => {
            if (!result || result.pending) {
              this.checkPaymentStatus(transactionId, callback, ttl + 2000)
            } else if (result.id) {
              callback(null, result)
            } else {
              callback(new Error('An unknown error occurred with the payment'))
            }
          }).catch(callback)
        }
      }, 2000)
    },
    initCurrentUserData () {
      this.loadingCurrentUserData = true
      this.$store.dispatch(D_GET_FROM_CACHE, 'currentUser').then((result) => {
        this.currentUserData = result
        if (this.currentUserData) {
          if (this.currentUserData.mobile) {
            this.mobile = this.currentUserData.mobile
          }
          if (this.currentUserData.dollars) {
            this.dollars = this.currentUserData.dollars
          }
          if (this.currentUserData.cents) {
            this.cents = this.currentUserData.cents
          }
          if (this.currentUserData.moneyTypeId) {
            this.mobileMoneyProvider = _.find(this.mobileMoneyProviders, o => (o.typeId === this.currentUserData.moneyTypeId))
          }
          if (this.currentUserData.authenticationType) {
            this.authenticationType = this.currentUserData.authenticationType
          }
        }
      }).catch((err) => {
        alert(`Error retrieving your stored phone number: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`)
      }).finally(() => {
        this.loadingCurrentUserData = false
      })
    }
  }
}
