<template>
  <div>
    <div v-if="$route.name === 'home'" id="appContent">
      <div class="mb-5">
        <h2 class="text-center">
          Welcome to SmartTransit Web
        </h2>
      </div>
<!--      <div class="d-flex justify-center align-center row mb-10" v-if="signInState === SIGN_IN_STATES.VERIFICATION || signInState === SIGN_IN_STATES.SIGN_IN">-->
<!--        <h3 class="text-center mb-3">Sign In</h3>-->
<!--        <div class="new-feature&#45;&#45;container elevation-3 pa-5" style="width: 100%">-->
<!--          <div class="new-feature&#45;&#45;icon">-->
<!--            <v-icon left small color="red">fas fa-sparkles</v-icon>-->
<!--            new-->
<!--          </div>-->
<!--          <div v-if="signInState === SIGN_IN_STATES.VERIFICATION">-->
<!--            <h4 class="text-center">Confirm Your Mobile Number To Sign In</h4>-->
<!--            <phone-number-field-->
<!--              class="mb-3"-->
<!--              :phone-number-country-codes="phoneCountryCodes"-->
<!--              :child-props="{required: true, disabled: isSubmitting, placeholder: 'Enter mobile number'}"-->
<!--              :value="initialPhoneNumber"-->
<!--              v-on:input="onPhoneNumberChange"-->
<!--            />-->
<!--            <div class="mb-3">-->
<!--              <v-btn block text @click="signInState = SIGN_IN_STATES.SIGN_IN" color="primary">Already have a code? Enter it</v-btn>-->
<!--            </div>-->
<!--            <v-btn block @click="sendVerificationCode" :disabled="!phoneNumber || isSubmitting || sendCodeTimeoutActive" :loading="isSubmitting">-->
<!--              <v-icon left>fal fa-sms</v-icon>&nbsp;-->
<!--              Send verification code-->
<!--            </v-btn>-->
<!--          </div>-->
<!--          <div v-if="signInState === SIGN_IN_STATES.SIGN_IN">-->
<!--            <h4 class="text-center mb-3">Enter Verification Code Sent As SMS And Sign In</h4>-->
<!--            <v-text-field-->
<!--              dense-->
<!--              autofocus-->
<!--              clearable-->
<!--              hide-details-->
<!--              class="mb-5"-->
<!--              clear-icon="fa fa-times-circle"-->
<!--              :disabled="isSubmitting"-->
<!--              placeholder="Enter verification code"-->
<!--              outlined-->
<!--              v-model="verificationCode"-->
<!--            />-->

<!--            <div class="mb-5">-->
<!--              <v-btn block small text color="primary" :disabled="isResending || !phoneNumber || sendCodeTimeoutActive" :loading="isResending" @click="resendVerificationCode">Didn't get a Code? Resend</v-btn>-->
<!--            </div>-->

<!--            <sign-in-form-->
<!--              class="elevation-0"-->
<!--              :on-sign-in-submitted="signInSubmitted"-->
<!--              :on-cancel="onSignInCancel"-->
<!--              :initial-mobile="initialPhoneNumber"-->
<!--              :phone-number-field-props="{ phoneNumberCountryCodes: phoneCountryCodes }"-->
<!--              :errors="signInErrors"-->
<!--              disable-email-->
<!--              disable-forgot-password-->
<!--            />-->

<!--            <div class="mt-5"><v-btn block text @click="startRecovery" color="primary">Forgot password? Recover account</v-btn></div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="d-flex justify-center align-center row mb-10" v-if="signInState === SIGN_IN_STATES.RECOVER_ACCOUNT">-->
<!--        <h3 class="text-center mb-3">Recover Account</h3>-->
<!--        <div class="new-feature&#45;&#45;container elevation-3 pa-5" style="width: 100%">-->
<!--          <h4 class="text-center mb-3">Enter the Phone of the Account you want to Recover</h4>-->
<!--          <phone-number-field-->
<!--              class="mb-5"-->
<!--              :phone-number-country-codes="phoneCountryCodes"-->
<!--              :child-props="{ clearable: true, clearIcon: 'fa fa-times-circle', disabled: isSubmitting }"-->
<!--              v-model="phoneNumber"-->
<!--          />-->
<!--          <div class="mb-5">-->
<!--            <v-btn block :disabled="isResending || !phoneNumber || sendCodeTimeoutActive" :loading="isResending" @click="resendVerificationCode">Send/Resend Verification Code</v-btn>-->
<!--          </div>-->

<!--          <h4 class="text-center mb-3">Enter the Verification Code From Above Number</h4>-->
<!--          <v-text-field-->
<!--              dense-->
<!--              autofocus-->
<!--              clearable-->
<!--              hide-details-->
<!--              class="mb-10"-->
<!--              clear-icon="fa fa-times-circle"-->
<!--              :disabled="isResending"-->
<!--              placeholder="Enter verification code"-->
<!--              outlined-->
<!--              v-model="verificationCode"-->
<!--          />-->

<!--          <h4 class="text-center mb-3">Recovery Contact is a Phone or Email?</h4>-->
<!--          <v-btn-toggle v-model="recoveryType" class="mb-2" style="width: 100%">-->
<!--            <v-btn width="50%" title="Recover with a mobile phone number" small text value="phone">-->
<!--              <span>Mobile phone</span>-->
<!--            </v-btn>-->
<!--            <v-btn width="50%" title="Recover with an email address" small text value="email">-->
<!--              <span>Email</span>-->
<!--            </v-btn>-->
<!--          </v-btn-toggle>-->

<!--          <v-text-field-->
<!--            v-if="recoveryType === 'email'"-->
<!--            dense-->
<!--            clearable-->
<!--            hide-details-->
<!--            outlined-->
<!--            class="mt-5 mb-8"-->
<!--            clear-icon="fa fa-times-circle"-->
<!--            :disabled="isSubmitting"-->
<!--            placeholder="Enter recovery email"-->
<!--            type="email"-->
<!--            v-model="recoveryEmail"-->
<!--          />-->
<!--          <phone-number-field-->
<!--            class="mt-3"-->
<!--            :default-prefix="defaultRecoveryPhonePrefix"-->
<!--            v-if="recoveryType === 'phone'"-->
<!--            :phone-number-country-codes="phoneCountryCodes"-->
<!--            :child-props="{ clearable: true, clearIcon: 'fa fa-times-circle', disabled: isSubmitting }"-->
<!--            v-model="recoveryPhone"-->
<!--          />-->

<!--          <div class="mb-10">-->
<!--            <v-btn block :disabled="!recoveryPhone && !recoveryEmail" @click="sendRecoveryCode">Send/Resend Recovery Code</v-btn>-->
<!--          </div>-->

<!--          <h4 class="text-center mb-3">Enter Recovery Code Received from Above Number</h4>-->
<!--          <v-text-field-->
<!--              dense-->
<!--              clearable-->
<!--              hide-details-->
<!--              outlined-->
<!--              class="mt-5 mb-10"-->
<!--              clear-icon="fa fa-times-circle"-->
<!--              :disabled="isSubmitting"-->
<!--              placeholder="Enter recovery code"-->
<!--              type="email"-->
<!--              v-model="recoveryVerificationCode"-->
<!--          />-->

<!--&lt;!&ndash;            <div class="mb-5">&ndash;&gt;-->
<!--&lt;!&ndash;              <v-btn block :disabled="isSubmitting || isResending" :loading="isSubmitting" @click="requestPassengerIdentifierId">Confirm Verification Code</v-btn>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->

<!--          <div class="mb-3">-->
<!--            <v-btn block :disabled="!phoneNumber || !recoveryVerificationCode" color="primary">Recover Account</v-btn>-->
<!--          </div>-->
<!--          <div><v-btn block text @click="cancelRecovery" color="warning">Cancel</v-btn></div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="d-flex justify-center row mb-5">-->
<!--        <div class="new-feature&#45;&#45;container" style="width: 100%">-->
<!--          <div class="new-feature&#45;&#45;icon">-->
<!--            <v-icon left small color="red">fas fa-sparkles</v-icon>-->
<!--            new-->
<!--          </div>-->
<!--          <v-btn block :to="{ name: 'register', params: { locale: $props.locale } }">-->
<!--            <v-icon left>fal fa-user-plus</v-icon>&nbsp;-->
<!--            Don't have an account? Register-->
<!--          </v-btn>-->
<!--        </div>-->
<!--      </div>-->
      <div class="d-flex justify-center row mb-5">
        <load-ticket-or-gift style="width: 100%;" :locale="$props.locale"/>
      </div>
      <div class="d-flex justify-center row mb-5">
        <v-btn text block color="primary" :to="{ name: 'scan-auto', params: { locale: $props.locale } }">
          Scan QR Code Or Enter Bus ID Now
        </v-btn>
      </div>
      <!--            <div class="d-flex justify-center">-->
      <!--                <div>-->
      <!--                    <div style="text-align: center"><small>Try the SmartTransit mobile app</small></div>-->
      <!--                    <a style="display: inline-block" target="_blank" href='https://play.google.com/store/apps/details?id=com.smarttransitio.passenger'>-->
      <!--                        <img width="187" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>-->
      <!--                    </a>-->
      <!--                    <div style="text-align: center"><small>iOS coming soon</small></div>-->
      <!--                </div>-->
      <!--            </div>-->
    </div>
    <router-view :page-height-offset="pageHeightOffset"/>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
import './components'
import src from './src'
export default src
</script>
