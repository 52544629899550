import { render, staticRenderFns } from "./template.vue?vue&type=template&id=20b12cbc&scoped=true&"
var script = {}
import style0 from "./template.vue?vue&type=style&index=0&id=20b12cbc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../quickpay/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20b12cbc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../quickpay/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VIcon,VProgressCircular})
