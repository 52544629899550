export const C_ADD_SITE_ALERT = 'addSiteAlert'
export const C_REMOVE_SITE_ALERT = 'removeSiteAlert'
export const C_HIDE_SITE_ALERT = 'hideSiteAlert'
export const C_SHOW_SITE_ALERT = 'showSiteAlert'
export const C_CLEAR_SITE_ALERTS = 'clearSiteAlerts'

export const C_SET_CACHE = 'setBrowserCache'
export const C_SET_BROWSER_SETTING = 'setBrowserSettings'

export const C_SET_TOKEN = 'token'
export const C_INVALIDATE_TOKEN = 'invalidateToken'
export const C_SET_SIGNED_IN_USER = 'setSignedInUser'
export const C_SET_SIGNED_USER_PROMISE = 'setSignedInUserPromise'

export const C_SET_BUS_STOP_DATA = 'setBusStopData'
export const C_CLEAR_BUS_STOP_DATA = 'clearBusStopData'
export const C_SET_CURRENT_TICKET = 'setCurrentTicket'
export const C_CLEAR_CURRENT_TICKET = 'clearCurrentTicket'

export const C_SET_REDIRECT_AFTER_EULA = 'setRedirectAfterEula'

export const C_SET_DISPLAY_RECONFIRM_CODE = 'setDisplayReconfirmCode'
