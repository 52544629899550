<template>
  <div>
    <div :class="errorOccurred ? '' : 'd-flex flex-column align-stretch'" v-if="profileData" v-show="!errorOccurred">
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <div class="list-number">1</div>
          </v-list-item-icon>
          <v-list-item-content>
            Please ask the conductor/driver for the fare amount
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="py-5 d-flex flex-row justify-center align-center">
        <div class="d-flex flex-row justify-center align-center" style="width: 180px">
          <div class="pa-1">
            <v-text-field
              dense
              :disabled="loadingCurrentUserData || paying"
              :prefix="currentCurrency"
              :rules="[v => (!v && !cents) ? 'Required' : true, v => (v && parseInt(v)) <= 5 || (!v && cents) ? true : 'Amount can only be ' + currentCurrency + '5 or less', v => (v && parseInt(v)) >= 0 || (!v && cents) ? true : 'Amount must 0 or higher']"
              type="number"
              style="text-align: right; min-width: 100px;"
              placeholder="0"
              outlined
              v-model="dollars"
            />
          </div>
          <div class="pa-1" style="vertical-align: middle; font-size: 2em; font-weight: bold;">
            .
          </div>
          <div class="pa-1">
            <v-text-field
              dense
              :disabled="loadingCurrentUserData || paying"
              :rules="[v => (!v && !dollars) ? 'Required' : true, v => (v && parseInt(v) >= 0 && parseInt(v) <= 99) || (!v && dollars) ? true : '0-99 required']"
              type="number"
              placeholder="00"
              outlined
              v-model="cents"
            />
          </div>
        </div>
      </div>
      <div class="px-5 pb-5">
        <div class="d-flex flex-row justify-space-between">
          <div class="mr-5">Bus</div>
          <div class="text-right"><code>{{profileData.licensePlateNumber}}</code></div>
        </div>
      </div>
      <div class="px-5 pb-5" v-show="profileData.location && profileData.location.toLowerCase().indexOf('unknown') === -1">
        <div class="d-flex flex-row justify-space-between">
          <div class="mr-5">Near</div>
          <div class="text-right"><code>{{profileData.location}}</code></div>
        </div>
      </div>
      <div class="px-5 mb-5 d-flex flex-row justify-center align-center" :style="{backgroundColor: '#fff'}">
        <v-radio-group v-model="authenticationType">
          <v-radio color="primary" off-icon="far fa-circle" on-icon="far fa-check-circle" value="unknownUser">
            <template v-slot:label>
              <div :class="authenticationType === 'unknownUser' ? 'primary--text' : ''"><small>Pay directly from Mobile Money</small></div>
            </template>
          </v-radio>
          <v-radio color="primary" off-icon="far fa-circle" on-icon="far fa-check-circle" value="secretPin">
            <template v-slot:label>
              <div :class="authenticationType === 'secretPin' ? 'primary--text' : ''"><small>Pay with SmartTransit account password</small></div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
      <v-expand-transition>
        <div v-show="authenticationType === 'secretPin'">
          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <div class="list-number">2</div>
              </v-list-item-icon>
              <v-list-item-content>
                Please enter your SmartTransit account info.
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div class="mb-2 d-flex flex-row justify-center align-center">
            <phone-number-field :props-prefix="currentCountryCode" :child-props="{disabled: paying, label: 'Mobile number'}" :value="mobile" v-model="mobile" />
          </div>
          <div class="mb-2 d-flex flex-row justify-center align-center">
            <v-text-field dense hide-details type="password" :disabled="paying" style="max-width: 250px"
                        label="SmartTransit password" outlined v-model="secretPin"></v-text-field>
          </div>
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div v-show="authenticationType === 'unknownUser'">
          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <div class="list-number">2</div>
              </v-list-item-icon>
              <v-list-item-content>
                Please enter your mobile money info.
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div class="mb-2 d-flex flex-row justify-center align-center">
            <phone-number-field :child-props="{disabled: paying, label: 'Mobile number'}" :props-prefix="currentCountryCode" v-model="mobile" />
          </div>
          <div class="mb-5 d-flex flex-row justify-center align-center">
            <v-menu>
              <template v-slot:activator="{ on }">
                <div class="mb-5 d-flex flex-row justify-start align-center">
                  <img v-if="mobileMoneyProvider" alt="selected logo" class="mr-2" height="50px"
                       :src="mobileMoneyProvider.src"/>
                  <v-btn text light :disabled="loadingCurrentUserData || paying" v-on="on">
                    <span v-if="mobileMoneyProvider">{{ mobileMoneyProvider.title }}</span>
                    <span v-if="!mobileMoneyProvider">Mobile money provider</span>
                    <v-icon class="ml-2" v-html="'fa fa-caret-down'"></v-icon>
                  </v-btn>
                </div>
              </template>
              <v-list>
                <v-list-item
                    v-for="(item, index) in mobileMoneyProviders"
                    :key="index"
                    @click="mobileMoneyProvider = item"
                >
                  <v-list-item-title class="mb-5 d-flex flex-row justify-space-between align-center">
                    <img class="mr-2" height="50px" alt="logo" :src="item.src"/>
                    <div>{{ item.title }}</div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-expand-transition>
      <div class="mb-5 d-flex flex-row justify-center align-center" v-if="paying">
        <pending-loader />
      </div>
      <div v-show="authenticationType">
        <div class="pa-5 d-flex flex-row justify-center align-center">
          <v-btn block color="primary" :disabled="loadingCurrentUserData || paying" :loading="paying" @click="payNow">
            Pay Now
          </v-btn>
        </div>
      </div>
      <div class="px-5 d-flex flex-row justify-center align-center">
        <router-link :to="{ name: 'scan-auto', params: { locale: $props.locale } }">
          <v-btn small text block light :disabled="loadingCurrentUserData || paying" color="warning">
            Cancel
          </v-btn>
        </router-link>
      </div>
    </div>
    <div v-if="errorOccurred" class="pa-5">
      <v-alert
          dense
          type="warning"
          icon="fas fa-exclamation-triangle"
          :style="{ maxHeight: 400, overflowY: 'auto' }"
          v-if="errorOccurred">
        <div v-html="errorOccurred"></div>
      </v-alert>
      <v-btn block @click="errorOkClicked">
        Ok
      </v-btn>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
import src from './src'

export default src
</script>
