import moment from 'moment'

import {
  C_SET_TOKEN,
  C_INVALIDATE_TOKEN,
  C_SET_SIGNED_IN_USER,
  C_SET_SIGNED_USER_PROMISE
} from '../utilities/mutation-types'

const LOCAL_STORAGE_PREFIX = 'st_'

function getToken (state) {
  if (!state.accessToken) {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_PREFIX + 'accessToken')

    if (accessToken) {
      state.accessToken = JSON.parse(accessToken)
    }
  }

  return state.accessToken
}

function getTokenId (state) {
  const accessToken = getToken(state)

  if (accessToken) {
    return accessToken.id
  }
}

export default {
  state: {
    user: null,
    userPromise: null,
    accessToken: null
  },
  getters: {
    tokenId: getTokenId,
    token: getToken,
    user: (state) => state.user
  },
  mutations: {
    [C_SET_TOKEN] (state, { newAccessToken }) {
      if (newAccessToken) {
        const expireDate = moment.utc(newAccessToken.created).add(newAccessToken.ttl, 'seconds')
        newAccessToken.expires = expireDate.valueOf()
        newAccessToken.renews = expireDate.subtract(newAccessToken.ttl - (10 * 60), 'seconds').valueOf()
      }
      state.accessToken = newAccessToken
      localStorage.setItem(LOCAL_STORAGE_PREFIX + 'accessToken', newAccessToken ? JSON.stringify(newAccessToken) : '')
    },
    [C_SET_SIGNED_IN_USER] (state, { newUser }) {
      state.user = newUser
    },
    [C_SET_SIGNED_USER_PROMISE] (state, { promise }) {
      state.userPromise = promise
    },
    [C_INVALIDATE_TOKEN] (state) {
      state.accessToken = null
      localStorage.setItem(LOCAL_STORAGE_PREFIX + 'accessToken', '')
      localStorage.removeItem(LOCAL_STORAGE_PREFIX + 'accessToken')
    }
  }
}
