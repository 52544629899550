import _ from 'lodash'
import moment from 'moment-timezone'
import { C_SET_CACHE, C_SET_BROWSER_SETTING, C_SET_REDIRECT_AFTER_EULA, C_SET_DISPLAY_RECONFIRM_CODE } from '../utilities/mutation-types'
import {
  D_GET_FROM_CACHE,
  D_SET_TO_CACHE,
  D_GET_FROM_BROWSER_SETTING
} from '../utilities/action-types'

const LOCAL_STORAGE_PREFIX = 'stqp_'
const cacheTimeoutHandles = {}

function _garbageCollectCache (cacheKey) {
  let previousExpiryTimestamp = localStorage.getItem(cacheKey + '_expiry_timestamp')
  if (previousExpiryTimestamp) {
    previousExpiryTimestamp = parseInt(previousExpiryTimestamp)
    if (Date.now() > previousExpiryTimestamp) {
      localStorage.removeItem(cacheKey)
      localStorage.removeItem(cacheKey + '_expiry_timestamp')
    }
  }
}

function _setCache (cacheKey, handle, { value, expiryInSeconds }) {
  expiryInSeconds = expiryInSeconds === undefined ? 60000 : expiryInSeconds // defaults to one minute
  localStorage.setItem(cacheKey, value && typeof value === 'object' ? JSON.stringify(value) : (value || ''))
  if (value) {
    if (expiryInSeconds) {
      localStorage.setItem(cacheKey + '_expiry_timestamp', moment().add(expiryInSeconds, 'seconds').valueOf())
    }
  } else {
    localStorage.removeItem(cacheKey)
    localStorage.removeItem(cacheKey + '_expiry_timestamp')
  }
}

export default {
  state: {
    browserSettings: {},
    redirectAfterEulaRoute: null
  },
  getters: {
    getRedirectAfterEulaRoute: function (state) {
      return state.redirectAfterEulaRoute
    }
  },
  mutations: {
    [C_SET_CACHE] (state, { key, value, expiryInSeconds }) {
      _setCache(key, cacheTimeoutHandles, { value, expiryInSeconds })
    },
    [C_SET_REDIRECT_AFTER_EULA] (state, route) {
      state.redirectAfterEulaRoute = route
    },
    [C_SET_DISPLAY_RECONFIRM_CODE] (state, toggle) {
      state.displayReconfirmCode = toggle
    },
    [C_SET_BROWSER_SETTING] (state, { key, value }) {
      const storageKey = LOCAL_STORAGE_PREFIX + 'setting_' + key
      const currentValue = localStorage.getItem(storageKey)
      if (_.isNil(value)) {
        if (state.browserSettings[key]) {
          delete state.browserSettings[key]
        }
        if (currentValue) {
          localStorage.setItem(storageKey, '')
          localStorage.removeItem(storageKey)
        }
      } else {
        state.browserSettings[key] = value
        localStorage.setItem(storageKey, value && typeof value === 'object' ? JSON.stringify(value) : (value || ''))
      }
    }
  },
  actions: {
    [D_GET_FROM_CACHE] ({ state, rootState }, key) {
      const cacheKey = LOCAL_STORAGE_PREFIX + 'cache_' + key
      _garbageCollectCache(cacheKey)
      const value = localStorage.getItem(cacheKey)
      return Promise.resolve(value ? (value.indexOf('[') === 0 || value.indexOf('{') === 0 ? JSON.parse(value) : value) : null)
    },
    [D_GET_FROM_BROWSER_SETTING] ({ state }, key) {
      const storageKey = `${LOCAL_STORAGE_PREFIX}setting_${key}`

      if (state.browserSettings[key]) {
        return state.browserSettings[key]
      }

      const value = localStorage.getItem(storageKey)
      return Promise.resolve(value ? (value.indexOf('[') === 0 || value.indexOf('{') === 0 ? JSON.parse(value) : value) : null)
    },
    [D_SET_TO_CACHE] ({ state, commit }, { key, value, expiryInSeconds }) {
      const cacheKey = LOCAL_STORAGE_PREFIX + 'cache_' + key
      commit(C_SET_CACHE, { key: cacheKey, value, expiryInSeconds })
      return Promise.resolve(true)
    }
  }
}
