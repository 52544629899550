<template>
  <div>
    <video v-if="!manualCameraMode" playsinline :style="videoStyle" :class="fullscreen ? 'fullscreen-video' : ''" :id="videoStreamId" :ref="videoStreamId" autoplay>
      <v-progress-circular indeterminate :size="50" :width="4" color="warning" />
    </video>
    <v-alert v-if="displayStreamFeedback && !manualCameraMode" dense :value="Boolean(streamFeedback)" type="info" class="my-2">
      {{streamFeedback}}
    </v-alert>
    <v-alert v-if="displayStreamFeedback && !manualCameraMode" dense :value="Boolean(streamErrorFeedback)" type="error">
      {{streamErrorFeedback}}
    </v-alert>
    <v-dialog v-if="manualCameraMode" v-model="cameraModal" fullscreen v-resize="onPageResize">
      <div class="dialog-close-container" style="background-color: #fff">
        <v-btn class="dialog-close" style="z-index: 200; top: 5px; right: 5px" fab depressed @click="cameraModal = false"><v-icon>far fa-times</v-icon></v-btn>
        <div :style="cameraModalDimensions ? `height: ${cameraModalDimensions.height}px; width: ${cameraModalDimensions.width}px` : 'height: 100%; width: 100%'">
          <video v-if="manualCameraMode" playsinline :style="videoStyle" class="fullscreen-video" :id="videoStreamId" :ref="videoStreamId" autoplay>
            <v-progress-circular indeterminate :size="50" :width="4" color="warning" />
          </video>
        </div>
        <div class="camera-modal-frame" :style="cameraModalFrameStyle" />
        <div class="camera-controls d-flex justify-center align-center">
          <div><v-btn fab @click="takePhoto"><v-icon>fa fa-camera</v-icon></v-btn></div>
          <div style="position: absolute; right: 30px; top: 0">
            <v-menu top close-on-content-click offset-y :disabled="!cameras.length">
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="!cameras.length" fab v-bind="attrs" v-on="on"><v-icon>fa fa-ellipsis-v</v-icon></v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in cameras" :key="index" @click="setCamera(item)">
                  <v-list-item-title><v-icon v-show="currentCamera === item.deviceId" class="mr-1">fa fa-check-circle</v-icon>{{item.label}}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.camera-modal-frame {
  position: absolute;
  height: auto;
  border: 4px solid #fff;
  border-radius: 5px;
  z-index: 200;
}

.camera-controls {
  position: absolute;
  bottom: 5%;
  width: 100%;
  z-index: 200;
}

.fullscreen-video {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  overflow: hidden;
}
</style>

<script>
import src from './src'
export default src
</script>
