<template>
  <v-dialog v-model="show" width="95%" @click:outside="closeDialog">
    <v-card :height="dialogHeight" v-resize="onContainerResize">
      <div class="reconfirm-code--text layout flex-row justify-center align-center pa-5">
        <div class="close-btn-container flex-fill">
          <div class="layout flex-row justify-center align-center">
            <div>
              YOU ARE CONFIRMED &nbsp; <v-icon color="#2550A7">far fa-check</v-icon>
            </div>
          </div>
          <v-btn icon @click="closeDialog" class="close-btn">
            <v-icon large>far fa-times</v-icon>
          </v-btn>
        </div>
      </div>
      <div v-if="reconfirmCode" class="layout flex-column justify-center align-center pb-5 px-5">
        <p style="text-align: center">Show the below code to the driver/conductor to verify your ticket</p>
        <div class="large_reconfirm-code--text mb-5">
          {{reconfirmCode}}
        </div>
        <div class="reconfirm-code--text">
          BUS: {{licensePlateNumber}}
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .large_reconfirm-code--text {
    font-size: 15em;
    line-height: 1em;
    font-weight: bold;
    background-color: #2550A7;
    color: #fff;
    padding: 10px;
    border-radius: 15px;
    text-transform: uppercase;
  }
  .reconfirm-code--text {
    font-size: 1.2em;
    line-height: 1em;
    color: #2550A7;
  }
</style>

<script>
import src from './src'

export default src
</script>
