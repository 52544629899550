import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import Home from './pages/home/template'
import { C_SET_REDIRECT_AFTER_EULA } from './utilities/mutation-types'
import { D_GET_FROM_CACHE } from './utilities/action-types'

Vue.use(Router)

const siteRouter = new Router({
  mode: 'history',
  base: process.env.VUE_BASE_URL,
  routes: [
    {
      path: '/',
      name: 'root',
      redirect: { name: 'home', params: { locale: process.env.VUE_APP_DEFAULT_LOCALE } }
    },
    {
      path: '/:locale?/',
      props: true,
      name: 'home',
      component: Home,
      children: [
        {
          path: 'register/',
          name: 'register',
          props: true,
          meta: { title: 'Register An Account' },
          component: () => import(/* webpackChunkName: "sign-in" */ './pages/register/template')
        },
        {
          path: 'scan/',
          name: 'scan-auto',
          props: true,
          meta: { title: 'Scan With Camera' },
          component: () => import(/* webpackChunkName: "scan-auto" */ './pages/ticket/scan/auto/template')
        },
        {
          path: 'scan/manual/',
          name: 'scan-manual',
          props: true,
          meta: { title: 'Type it in' },
          component: () => import(/* webpackChunkName: "scan-manual" */ './pages/ticket/scan/manual/template')
        },
        {
          path: 'pay/:transportationProfileId?/',
          name: 'pay',
          props: true,
          meta: { title: 'Detect Bus ID and Pay Fare' },
          component: () => import(/* webpackChunkName: "pay" */ './pages/ticket/pay/template')
        },
        {
          path: 't/:ticketId/',
          name: 'ticket',
          props: true,
          meta: { title: 'Display ticket' },
          component: () => import(/* webpackChunkName: "ticket" */ './pages/ticket/template')
        },
        {
          path: 'gt/send/',
          name: 'send-gifted-ticket',
          props: true,
          meta: { title: 'Send gifted ticket' },
          component: () => import(/* webpackChunkName: "send gifted ticket" */ './pages/gifted-ticket/send/template')
        },
        {
          path: 'gt/:origin/:giftedTicketId/',
          name: 'gifted-ticket',
          props: true,
          meta: { title: 'Display gifted ticket' },
          component: () => import(/* webpackChunkName: "gifted ticket" */ './pages/gifted-ticket/template')
        },
        {
          path: 'g/send/',
          name: 'send-gift',
          props: true,
          meta: { title: 'Send gift' },
          component: () => import(/* webpackChunkName: "send gift" */ './pages/gift/send/template')
        },
        {
          path: 'g/:origin/:giftId/',
          name: 'gift',
          props: true,
          meta: { title: 'Display gift' },
          component: () => import(/* webpackChunkName: "gift" */ './pages/gift/template')
        },
        {
          path: 'eula/',
          name: 'eula',
          props: true,
          meta: { title: 'EULA' },
          component: () => import(/* webpackChunkName: "eula" */ './pages/eula/template')
        }
      ]
    }
  ]
})

siteRouter.beforeEach((to, from, next) => {
  async function checkEula () {
    const eula = await store.dispatch(D_GET_FROM_CACHE, 'eula')
    if (eula !== 'true' && !['home', 'ticket', 'eula', 'gifted-ticket', 'gift'].includes(to.name)) {
      store.commit(C_SET_REDIRECT_AFTER_EULA, to)
      next(false)
      siteRouter.push({ name: 'eula', params: { locale: to.params.locale } })
    } else {
      next()
    }
  }
  checkEula()
})

export default siteRouter
