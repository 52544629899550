import { apiRequests } from '../utilities/axios-factory'
import store from '../store'

function sendVerificationCodeText ({ phoneNumber }) {
  return apiRequests.patch('/v1/passenger-identifier/generic-verification/', {
    phoneNumber
  }).then((result) => {
    return result.data
  })
}

function confirmVerificationCodeForIdentifierId ({ phoneNumber, verificationCode }) {
  return apiRequests.patch('/v1/passenger-identifier/generic-verification/confirm-for-id/', {
    phoneNumber,
    verificationCode
  }).then((result) => {
    return result.data
  })
}

function sendRecoveryRequest ({ passengerIdentifierId, phoneNumber, email }) {
  return apiRequests.post(`/v1/passenger-identifier/${passengerIdentifierId}/recovery-request/`, {
    recoveryContact: phoneNumber || email
  }).then((result) => {
    return result.data
  })
}

function findCountryCurrencies (filter) {
  return apiRequests.get('/v1/country-currency/join/', {
    params: { filter },
    headers: { Authorization: store.getters.tokenId }
  }).then((result) => {
    return result.data
  })
}

const phoneNumberCountryCodes = [
  { code: '+233', title: 'Ghana', countryId: 'GHA' },
  { code: '+1', title: 'North America (Canada, US)', countryId: 'GHA' },
  { code: '+353', title: 'Ireland', countryId: 'GHA' },
  { code: '+44', title: 'United Kingdom', countryId: 'GHA' }
]

let countryCurrencies = null

async function getCountryCurrencies () {
  if (!countryCurrencies) {
    countryCurrencies = await findCountryCurrencies()

    if (countryCurrencies && countryCurrencies.length) {
      countryCurrencies = {}

      countryCurrencies.forEach((currency) => {
        countryCurrencies[currency.countryId] = currency
      })
    }
  }
  return countryCurrencies
}

export {
  sendRecoveryRequest,
  confirmVerificationCodeForIdentifierId,
  sendVerificationCodeText,
  phoneNumberCountryCodes,
  getCountryCurrencies,
  findCountryCurrencies
}
