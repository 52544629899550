<template>
  <v-app>
    <v-container>
      <v-row justify="center" align="center" class="mb-2">
        <router-link id="siteHeader" ref="siteHeader" v-resize="onPageResize" :to="{ name: 'home', params: { locale: currentLocale } }" style="text-decoration: none">
          <img src="@/assets/images/logo.png" height="35" alt="SmartTransit Web" /> <em><strong>WEB</strong></em>
        </router-link>
      </v-row>
      <v-row justify="center">
        <div id="appContent">
          <v-main>
            <router-view :page-height-offset="pageHeightOffset" />
          </v-main>
        </div>
      </v-row>
    </v-container>
    <v-footer fixed app id="siteFooter" ref="siteFooter" v-resize="onPageResize" class="px-2">
      <div class="d-flex flex-grow-1 flex-column align-center justify-center">
<!--        <div v-if="$route.name !== 'home'" class="text-center">-->
<!--          <div><small class="text-center grey&#45;&#45;text">Try the SmartTransit mobile app</small></div>-->
<!--          <a class="d-inline" target="_blank" href='https://play.google.com/store/apps/details?id=com.smarttransitio.passenger'>-->
<!--            <img height="40" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>-->
<!--          </a>-->
<!--        </div>-->
        <div><a href="https://www.smarttransit.io" target="_blank"><small>visit smarttransit.io</small></a>&nbsp; | &nbsp; <small class="grey--text">v2.0.0</small></div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import src from './src'
export default src
</script>
