import { apiRequests } from '../utilities/axios-factory'
import store from '../store'
import _ from 'lodash'
import { C_SET_SIGNED_IN_USER, C_SET_TOKEN } from '../utilities/mutation-types'

function signOut () {
  return apiRequests.post('/v1/st-user/logout/', null, {
    headers: { Authorization: store.getters.tokenId }
  })
}

async function signIn ({ verificationCode, phoneNumber, password }) {
  const result = await apiRequests.post('/v1/passenger-identifier/verification-code-login-token/', {
    verificationCode,
    phoneNumber,
    password
  })

  if (!_.get(result, 'data.stUser.roles', []).find((o) => ['passenger'].includes(o.name))) {
    throw Object.assign(new Error('User not authorized to access this account'), { status: 403 })
  }

  return result?.data
}

function setAuthData (authToken) {
  if (!authToken) {
    throw new Error('Cannot set an undefined token')
  }

  if (authToken.token?.id) {
    console.log('authToken=>', authToken)
    store.commit(C_SET_TOKEN, { newAccessToken: authToken.token })

    if (authToken.stUser || authToken.stUser === null) {
      store.commit(C_SET_SIGNED_IN_USER, { ...authToken.stUser, roles: [{ name: 'passenger' }] })
    }
  }
}

function getSignedInUser () {
  const user = store.getters.user

  if (!user) {
    const token = store.getters.token

    if (token) {
      return apiRequests.get(`/v1/st-user/${token.userId}/`, {
        headers: { Authorization: token.id },
        params: { filter: { include: ['roles', 'passengerProfile'] } }
      }).then((result) => {
        if (!result?.data?.passengerProfile) {
          return Promise.reject(new Error('No user found with passenger profile'))
        }

        return result.data
      })
    }
  }

  return Promise.resolve(user)
}

function passwordResetRequest ({ email, phone }) {
  return apiRequests.put('/v1/passenger-identifier/password-reset-request/', { email, phone }).then(result => result.data)
}

function submitPasswordReset ({ verificationToken, password, confirmedPassword }) {
  return apiRequests.put('/v1/st-user/password-reset/', { verificationToken, password, confirmedPassword }).then(result => result.data)
}

export {
  signOut,
  signIn,
  setAuthData,
  getSignedInUser,
  passwordResetRequest,
  submitPasswordReset
}
