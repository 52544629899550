import { apiRequests } from '../utilities/axios-factory'
import {
  D_GET_VEHICLE_DATA_BY_ID,
  D_PAY_FARE,
  D_CHECK_PAYMENT,
  D_GET_TICKET,
  D_RECONFIRM_TRANSPORTATION
} from '../utilities/action-types'
import {
  C_SET_BUS_STOP_DATA,
  C_CLEAR_BUS_STOP_DATA,
  C_SET_CURRENT_TICKET,
  C_CLEAR_CURRENT_TICKET
} from '../utilities/mutation-types'

export default {
  state: {
    currentTicket: null,
    busStopData: null
  },
  getters: {
    currentTicket: function (state) {
      return state.currentTicket
    },
    busStopData: function (state) {
      return state.busStopData
    }
  },
  mutations: {
    [C_SET_CURRENT_TICKET] (state, ticket) {
      state.currentTicket = ticket
    },
    [C_CLEAR_CURRENT_TICKET] (state) {
      state.currentTicket = null
    },
    [C_SET_BUS_STOP_DATA] (state, busStopData) {
      state.busStopData = busStopData
    },
    [C_CLEAR_BUS_STOP_DATA] (state) {
      state.busStopData = null
    }
  },
  actions: {
    [D_GET_VEHICLE_DATA_BY_ID] ({ commit, getters }, id) {
      return apiRequests.get('/v1/transportation-profile/' + id + '/quick-pay-data/').then((result) => {
        return result.data
      })
    },
    [D_PAY_FARE] ({ commit, getters }, data) {
      return apiRequests.post('/v1/passenger-transaction-log/quick-pay/', data).then((result) => {
        return result.data
      })
    },
    [D_CHECK_PAYMENT] ({ commit, getters }, id) {
      return apiRequests.get('/v1/passenger-transaction-log/' + id + '/quick-pay/status/').then((result) => {
        if (result.data && result.data.id) {
          commit(C_SET_CURRENT_TICKET, result.data)
        } else {
          commit(C_CLEAR_CURRENT_TICKET)
        }
        return result.data
      })
    },
    [D_GET_TICKET] ({ commit, getters }, id) {
      return apiRequests.get('/v1/passenger-transaction-log/' + id + '/quick-pay/').then((result) => {
        return result.data
      })
    },
    [D_RECONFIRM_TRANSPORTATION] ({ commit, getters }, data) {
      return apiRequests.patch('/v1/passenger-transaction-log/' + data.id + '/quick-pay/re-confirm/', {
        transportationProfileId: data.transportationProfileId || undefined,
        lat: data.lat || undefined,
        long: data.long || undefined
      }).then((result) => {
        return result.data
      })
    }
  }
}
