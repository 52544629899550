export default {
  name: 'sign-in-form',
  props: {
    initialMobile: String,
    initialEmail: String,
    onCancel: Function,
    onSignInSubmitted: Function,
    onForgotPasswordSubmitted: Function,
    onSignInTypeClicked: Function,
    disableSignIn: Boolean,
    disableForgotPassword: Boolean,
    phoneNumberFieldProps: Object,
    defaultSubmissionType: {
      validator: function (value) {
        // The value must match one of these strings
        return ['email', 'mobile'].includes(value)
      },
      default: 'mobile'
    },
    defaultState: {
      validator: function (value) {
        // The value must match one of these strings
        return ['signIn', 'forgotPassword'].includes(value)
      },
      default: 'signIn'
    },
    disableEmail: Boolean,
    disableMobile: Boolean,
    errors: Array
  },
  data () {
    return {
      loginType: null,
      email: null,
      mobile: null,
      password: null,
      dataErrors: [],
      formState: null,
      passwordRules: [
        function (data) {
          if (!data || !data.trim()) {
            return 'Password is required'
          }
          return true
        }
      ],
      emailRules: [
        function (data) {
          if (!data || !data.trim()) {
            return 'Email is required'
          }
          return true
        },
        function (data) {
          if (!data || !/^.+@.+\..+$/.test(data)) {
            return 'Correct email format required'
          }
          return true
        }
      ]
    }
  },
  computed: {
    getFormErrors () {
      const _errors = this.errors || []
      return this.dataErrors ? this.dataErrors.concat(_errors) : _errors
    }
  },
  watch: {
    defaultSubmissionType (val) {
      if (['mobile', 'email', null, ''].includes(val)) {
        this.loginType = val
      }
    },
    disableEmail (val) {
      if (val) {
        this.loginType = 'mobile'
      }
    },
    disableMobile (val) {
      if (val) {
        this.loginType = 'email'
      }
    },
    defaultState (val) {
      if (val) {
        this.formState = val
      }
    }
  },
  mounted: function () {
    if (['mobile', 'email', null, ''].includes(this.$props.defaultSubmissionType)) this.loginType = this.$props.defaultSubmissionType

    if (this.$props.disableEmail) {
      this.loginType = 'mobile'
    }

    if (this.$props.disableMobile) {
      this.loginType = 'email'
    }

    if (this.$props.defaultState) {
      this.formState = this.$props.defaultState
    }

    if (this.$props.initialMobile) {
      this.mobile = this.$props.initialMobile
    }

    if (this.$props.initialEmail) {
      this.email = this.$props.initialEmail
    }
  },
  methods: {
    onPhoneNumberChange (val) {
      this.mobile = val
    },
    setLoginType (loginType) {
      this.loginType = loginType

      if (this.$props.onSignInTypeClicked) {
        this.$props.onSignInTypeClicked(this.loginType)
      }
    },
    backToForm () {
      this.dataErrors = []

      if (!this.$props.disableSignIn) {
        this.formState = 'signIn'
      } else if (!this.$props.disableForgotPassword) {
        this.formState = 'forgotPassword'
      }
    },
    cancelForgotPassword () {
      let cancelResult

      if (this.$props.onCancel) {
        cancelResult = this.$props.onCancel()
      }

      if (cancelResult?.then) {
        cancelResult.then(() => {
          this.dataErrors = []
          this.formState = 'signIn'
        })
      } else {
        this.dataErrors = []
        this.formState = 'signIn'
      }
    },
    cancel () {
      let cancelResult

      if (this.$props.onCancel) {
        cancelResult = this.$props.onCancel()
      }

      if (cancelResult?.then) {
        cancelResult.then(() => {
          _resetForm.call(this)
        })
      } else {
        _resetForm.call(this)
      }
    },
    loginForm (e) {
      this.dataErrors = []

      if (this.loginType === 'mobile') {
        if (!this.mobile || !this.mobile.trim()) {
          return 'Mobile number is required'
        }

        if (this.formState === 'signIn') {
          if (!this.password || !this.password.trim()) {
            return 'Password is required'
          }
        }

        if (this.mobile.indexOf('+') !== 0) {
          return this.dataErrors.push('Mobile number country code is required')
        }
      } else if (this.loginType === 'email') {
        if (!this.email) {
          return
        }

        if (!/^.+@.+\..+$/.test(this.email)) {
          return this.dataErrors.push('Provide a valid email address')
        }
      }

      if (this.formState === 'signIn') {
        _login.call(this)
      } else {
        _sendForgotPasswordRequest.call(this)
      }

      return false
    }
  }
}

function _login () {
  const dispatchParams = {}

  if (this.loginType === 'mobile') {
    dispatchParams.phoneNumber = this.mobile
  } else if (this.loginType === 'email') {
    dispatchParams.email = this.email
  }

  dispatchParams.password = this.password

  if (this.$props.onSignInSubmitted) {
    this.$props.onSignInSubmitted(dispatchParams)
      .catch(err => {
        this.dataErrors.push(err?.error ? `${err.error.status}: ${err.error.message}` : JSON.stringify(err))
        throw err
      })
  }
}

function _resetForm () {
  this.email = ''
  this.mobile = ''
  this.password = ''
  this.dataErrors = []
}

function _sendForgotPasswordRequest () {
  const dispatchParams = {}

  if (this.loginType === 'mobile') {
    dispatchParams.phone = this.mobile
  } else if (this.loginType === 'email') {
    dispatchParams.email = this.email
  }

  if (this.$props.onForgotPasswordSubmitted) {
    this.$props.onForgotPasswordSubmitted(dispatchParams)
      .then(() => {
        this.formState = 'sent'
      })
      .catch((err) => {
        this.dataErrors.push(err && err.error ? `${err.error.status}: ${err.error.message}` : JSON.stringify(err))
        throw err
      })
  }
}
