import { getCameraDevices } from './utils'

export default {
  name: 'st-video-snapshot',
  props: {
    onVideoStreamStarted: Function,
    onVideoStreamEnded: Function,
    displayStreamFeedback: Boolean,
    camera: [String, Boolean],
    nextSnapshotTimestamp: Number,
    onSnapshotTaken: Function,
    onSnapshotFailed: Function,
    videoStyle: String,
    fullscreen: Boolean,
    manualCameraMode: Boolean,
    onManualCameraClosed: Function,
    videoStreamId: {
      type: String,
      required: true
    },
    contextFilter: Function,
    returnType: {
      type: String,
      default: 'url'
    },
    resolution: Object // object of { width, height }
  },
  data () {
    return {
      streamLoading: false,
      isCameraOpen: null,
      cameraModalDimensions: null,
      streamErrorFeedback: '',
      streamFeedback: '',
      cameras: [],
      cameraModal: true,
      currentCamera: null
    }
  },
  mounted () {
    startCameraStream.call(this)

    if (this.$props.fullscreen || this.$props.manualCameraMode) {
      document.getElementsByTagName('html')[0].classList.add('overflow-hidden')
      document.getElementsByTagName('body')[0].classList.add('overflow-hidden')
    }

    if (this.camera) {
      this.currentCamera = this.camera
    }
  },
  computed: {
    cameraModalFrameStyle () { // 7
      if (this.cameraModalDimensions) {
        const reducedWidth = this.cameraModalDimensions.width * 0.8
        const reducedHeight = this.cameraModalDimensions.height * 0.6
        return `width: ${reducedWidth}px; height: ${reducedHeight}px; top: ${((this.cameraModalDimensions.height - reducedHeight) / 3) + 'px'}; left: ${((this.cameraModalDimensions.width - reducedWidth) / 2) + 'px'}`
      }

      return 'width: 400px; height: 400px; top: 10%; left: 10%'
    }
  },
  watch: {
    camera (newVal) {
      this.currentCamera = newVal

      if (newVal) {
        stopCameraStream.call(this)
        startCameraStream.call(this)
      }
    },
    manualCameraMode (val) {
      if (val) {
        this.cameraModal = true
        document.getElementsByTagName('html')[0].classList.add('overflow-hidden')
        document.getElementsByTagName('body')[0].classList.add('overflow-hidden')
      } else {
        document.getElementsByTagName('html')[0].classList.remove('overflow-hidden')
        document.getElementsByTagName('body')[0].classList.remove('overflow-hidden')
      }
    },
    cameraModal (val) {
      if (!val) {
        if (this.$props.onManualCameraClosed) {
          this.$props.onManualCameraClosed()
        }
      }
    },
    fullscreen (val) {
      if (val) {
        document.getElementsByTagName('html')[0].classList.add('overflow-hidden')
        document.getElementsByTagName('body')[0].classList.add('overflow-hidden')
      } else {
        document.getElementsByTagName('html')[0].classList.remove('overflow-hidden')
        document.getElementsByTagName('body')[0].classList.remove('overflow-hidden')
      }
    },
    nextSnapshotTimestamp (newVal) { //
      (async () => {
        if (newVal && this.lastSnapshotTimestamp !== newVal) {
          try {
            const base64File = await takeSnapshot.call(this)
            this.onSnapshotTaken && this.onSnapshotTaken(base64File)
          } catch (err) {
            console.error(err)
            this.onSnapshotFailed && this.onSnapshotFailed(err)
          }

          this.lastSnapshotTimestamp = newVal
        }
      })()
    }
  },
  methods: {
    onPageResize () {
      this.cameraModalDimensions = { height: window.innerHeight, width: window.innerWidth }
    },
    async takePhoto () {
      try {
        const base64File = await takeSnapshot.call(this)
        this.onSnapshotTaken && this.onSnapshotTaken(base64File)
      } catch (err) {
        console.error(err)
        this.onSnapshotFailed && this.onSnapshotFailed(err)
      }
    },
    async setCamera (camera) {
      if (this.currentCamera !== camera.deviceId) {
        this.currentCamera = camera.deviceId
        stopCameraStream.call(this)
        startCameraStream.call(this)
      }
    }
  },
  beforeDestroy () {
    stopCameraStream.call(this)

    if (this.$props.fullscreen || this.$props.manualCameraMode) {
      document.getElementsByTagName('html')[0].classList.remove('overflow-hidden')
      document.getElementsByTagName('body')[0].classList.remove('overflow-hidden')
    }
  }
}

// eslint-disable-next-line no-unused-vars
function takeSnapshot () {
  const newCanvas = document.createElement('canvas')
  const context = newCanvas.getContext('2d')
  let newCanvasWidth = this.streamSettings.width
  let newCanvasHeight = this.streamSettings.height

  if (this.$props.resolution) {
    const maxWidth = this.$props.resolution.width
    const maxHeight = this.$props.resolution.height
    var ratioW = maxWidth / this.streamSettings.width
    var ratioH = maxHeight / this.streamSettings.height

    // If height ratio is bigger then we need to scale height
    if (ratioH > ratioW) {
      newCanvasWidth = maxWidth
      newCanvasHeight = this.streamSettings.height * ratioW
    } else {
      newCanvasHeight = maxHeight
      newCanvasWidth = this.streamSettings.height * ratioH
    }

    // Alternative approach
    // const longEdge = Math.max(this.streamSettings.width, this.streamSettings.height)
    // const ratio = this.$props.resolution.width < this.$props.resolution.height ? (this.$props.resolution.width / longEdge) : (this.streamSettings.height / longEdge)
    // newCanvasWidth = (ratio) * this.streamSettings.width
    // newCanvasHeight = (ratio) * this.streamSettings.height
  }

  if (this.$props.contextFilter) {
    this.$props.contextFilter({
      context,
      videoStreamRef: this.$refs[this.videoStreamId],
      canvasWidth: newCanvasWidth,
      canvasHeight: newCanvasHeight
    })
  } else {
    newCanvas.width = newCanvasWidth
    newCanvas.height = newCanvasHeight
    context.drawImage(this.$refs[this.videoStreamId], 0, 0, newCanvas.width, newCanvas.height)
  }

  console.warn('image data', {
    newCanvasWidth,
    newCanvasHeight,
    availableWidth: this.streamSettings.width,
    availableHeight: this.streamSettings.height,
    requestedWidth: this.$props.resolution.width,
    requestedHeight: this.$props.resolution.height
  })

  if (this.$props.returnType === 'file') {
    return new Promise((resolve, reject) => {
      newCanvas.toBlob((blob) => {
        if (!blob) {
          return reject(new Error('No snapshot could be taken'))
        }

        // debug code:
        // const newImg = document.createElement('img')
        // newImg.width = this.$refs[this.videoStreamId].videoWidth
        // newImg.style = 'position: absolute; top: 0; left: 0;'
        // newImg.src = URL.createObjectURL(blob)
        // document.body.appendChild(newImg)
        //
        // setTimeout(() => {
        //   newImg.remove()
        // }, 5000)

        resolve(new File([blob], `snapshot${Date.now()}.jpg`, { type: blob.type }))
      }, 'image/jpeg', 1)
    })
  }

  return newCanvas.toDataURL('image/jpeg')
}

function stopCameraStream () {
  const tracks = this.$refs[this.videoStreamId]?.srcObject?.getTracks()

  if (tracks) {
    tracks.forEach(track => {
      track.stop()
    })
  }

  if (this.$refs[this.videoStreamId]) {
    this.$refs[this.videoStreamId].srcObject = null
  }

  this.isCameraOpen = false

  if (this.$props.onVideoStreamEnded) {
    this.$props.onVideoStreamEnded()
  }
}

async function startCameraStream () {
  try {
    if (this.$refs[this.videoStreamId].srcObject) {
      stopCameraStream.call(this)
    }

    this.streamLoading = true

    let videoConstraint = this.$props.resolution ? {
      width: { ideal: this.$props.resolution.width },
      height: { ideal: this.$props.resolution.height }
    } : null

    let videoConstraintModes

    if (this.currentCamera && (this.currentCamera === 'environment' || this.currentCamera === 'user')) {
      videoConstraintModes = this.currentCamera === 'environment' ? { facingMode: { exact: 'environment' } } : { facingMode: 'user' }
    } else if (this.currentCamera && typeof this.currentCamera === 'string') {
      videoConstraintModes = { deviceId: { exact: this.currentCamera } }
    }

    this.$refs[this.videoStreamId].srcObject = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: videoConstraint || videoConstraintModes ? { ...(videoConstraint || {}), ...(videoConstraintModes || {}) } : true
    })

    this.streamSettings = this.$refs[this.videoStreamId].srcObject.getVideoTracks()[0].getSettings()
    const devices = await getCameraDevices()
    this.isCameraOpen = true
    this.cameras = devices

    if (this.$props.onVideoStreamStarted) {
      this.$props.onVideoStreamStarted({ settings: { ...this.streamSettings }, devices: [].concat(devices) })
    }
  } catch (err) {
    console.error(err)
    this.streamErrorFeedback = err.message

    if (this.$props.onSnapshotFailed) {
      this.$props.onSnapshotFailed(Object.assign(new Error(err.message || (`${err.name}: ${err.constaint}`)), { ...err }))
    }
  } finally {
    this.streamLoading = false
  }
}
