<template>
  <div class="phone-container">
    <div class="mr-3">
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon text light v-on="on" style="width: auto" :disabled="childProps && childProps.readonly">
            <span v-show="prefix">{{ prefix }}</span>
            <v-icon v-show="!prefix" v-html="'fal fa-globe-africa'"/>&nbsp;
            <v-icon v-html="'fa fa-caret-down'"/>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              v-for="(item, index) in items"
              :key="index"
              @click="setPrefix(item)"
          >
            <v-list-item-title><small>{{ item.code }}</small> {{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-text-field
      ref="numberInput"
      :key="numberValueKey"
      v-bind="childProps"
      v-model="numberValue"
      :placeholder="currentMask"
      type="tel"
      :error-messages="errors"
      :rules="rules" />
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.phone-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>

<script>
import src from './src'
export default src
</script>
