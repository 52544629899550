import '@fortawesome/fontawesome-pro/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Resize } from 'vuetify/es5/directives'
// import 'vuetify/src/styles/main.sass'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    primary: '#204da2',
    secondary: '#67be7b',
    accent: '#82B1FF',
    error: '#f65122',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  },
  directives: {
    Resize
  },
  icons: {
    iconfont: 'fal',
    cancel: 'fal fa-ban',
    menu: 'fal fa-ellipsis-v-alt'
  }
})
// {
//   components: {
//     VApp,
//     VNavigationDrawer,
//     VFooter,
//     VList,
//     VBtn,
//     VIcon,
//     VGrid,
//     VToolbar,
//     VCard,
//     VForm,
//     VTextField,
//     VSelect,
//     VSwitch,
//     VTimePicker,
//     VDatePicker,
//     VMenu,
//     VBtnToggle,
//     VAlert,
//     VSnackbar,
//     VAutocomplete,
//     VDivider,
//     VDialog,
//     VDataTable,
//     VTooltip,
//     VTabs,
//     VProgressLinear,
//     transitions
//   },
//   theme: {
//     primary: '#204da2',
//     secondary: '#67be7b',
//     accent: '#82B1FF',
//     error: '#f65122',
//     info: '#2196F3',
//     success: '#4CAF50',
//     warning: '#FFC107'
//   },
//   iconfont: 'fal',
//   icons: {
//     'cancel': 'fal fa-ban',
//     'menu': 'fal fa-ellipsis-v-alt'
//   }
// })
