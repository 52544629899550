/* eslint-disable no-unused-vars */
import {
  confirmVerificationCodeForIdentifierId,
  phoneNumberCountryCodes,
  sendRecoveryRequest,
  sendVerificationCodeText
} from '../../services/passenger-identifier-service'
import { addAlert } from '../../utilities/helpers'
import { getCache, setCache } from '../../services/cache-service'
import { setAuthData, signIn } from '../../services/credentials-service'
import { extractPhonePrefixFromNumber, maskPhoneNumber } from '@smarttransit/common-client'

const SIGN_IN_STATES = {
  VERIFICATION: 'verification',
  SIGN_IN: 'signIn',
  RECOVER_ACCOUNT: 'recoverAccount'
}

export default {
  name: 'home',
  props: {
    locale: String,
    pageHeightOffset: Number
  },
  data () {
    return {
      phoneCountryCodes: [],
      signInState: SIGN_IN_STATES.VERIFICATION,
      SIGN_IN_STATES,
      verificationCode: null,
      recoveryVerificationCode: null,
      phoneNumber: '',
      initialPhoneNumber: '',
      recoveryType: 'phone',
      recoveryEmail: '',
      recoveryPhone: '',
      sendRecoveryCodeLabel: '',
      defaultSendRecoveryCodeLabel: 'Send Recovery Code',
      defaultRecoveryPhonePrefix: '',
      passengerIdentifierId: null,
      sendCodeTimeoutActive: false,
      isSubmitting: false,
      isResending: false,
      signInErrors: null
    }
  },
  mounted () {
    this.phoneCountryCodes = phoneNumberCountryCodes
    this.initialPhoneNumber = getCache('signInPhoneNumber') || ''
    this.sendRecoveryCodeLabel = this.defaultSendRecoveryCodeLabel
  },
  computed: {

  },
  watch: {
    recoveryEmail () {
      this.sendRecoveryCodeLabel = this.defaultSendRecoveryCodeLabel
    },
    recoveryPhone () {
      this.sendRecoveryCodeLabel = this.defaultSendRecoveryCodeLabel
    },
    phoneNumber (val) {
      if (val) {
        this.phoneNumberLabel = maskPhoneNumber(val, this.phoneCountryCodes)
      } else {
        this.phoneNumberLabel = '(number not set)'
      }
    }
  },
  methods: {
    onPhoneNumberChange (val) {
      this.phoneNumber = val
    },
    async sendVerificationCode () { //
      try {
        this.isSubmitting = true
        await sendVerificationCodeText({ phoneNumber: this.phoneNumber })
        this.initialPhoneNumber = this.phoneNumber
        setCache('signInPhoneNumber', this.phoneNumber, null)
        this.signInState = SIGN_IN_STATES.SIGN_IN
        this.startSendCodeTimeout()
      } catch (err) {
        console.log(err)
        addAlert({ message: err, type: 'error' })
      } finally {
        this.isSubmitting = false
      }
    },
    async resendVerificationCode () {
      try {
        this.isResending = true
        await sendVerificationCodeText({ phoneNumber: this.phoneNumber })
        this.verificationCode = ''
        this.initialPhoneNumber = this.phoneNumber
        setCache('signInPhoneNumber', this.phoneNumber, null)
        this.startSendCodeTimeout()
      } catch (err) {
        console.log(err)
        addAlert({ message: err, type: 'error' })
      } finally {
        this.isResending = false
      }
    },
    startSendCodeTimeout () {
      if (this.startSendCodeTimeoutHandle) {
        clearTimeout(this.startSendCodeTimeoutHandle)
      }

      this.sendCodeTimeoutActive = true

      this.startSendCodeTimeoutHandle = setTimeout(() => {
        this.sendCodeTimeoutActive = false
      }, 5000)
    },
    async requestPassengerIdentifierId () {
      try {
        this.isSubmitting = true
        const result = await confirmVerificationCodeForIdentifierId({ verificationCode: this.verificationCode, phoneNumber: this.phoneNumber })
        this.passengerIdentifierId = result?.passengerIdentifierId
      } catch (err) {
        console.log(err)
        addAlert({ message: err, type: 'error' })
      } finally {
        this.isSubmitting = false
      }
    },
    async signInSubmitted ({ phoneNumber, password }) {
      try {
        this.isSubmitting = true
        const result = await signIn({ verificationCode: this.verificationCode, phoneNumber, password })
        this.phoneNumber = phoneNumber
        setCache('signInPhoneNumber', phoneNumber, null)
        setAuthData(result.token)
      } catch (err) {
        console.log(err)
        addAlert({ message: err, type: 'error' })
      } finally {
        this.isSubmitting = false
      }
    },
    async sendRecoveryCode () {
      try {
        this.isSubmitting = true

        await sendRecoveryRequest({
          passengerIdentifierId: this.passengerIdentifierId,
          phoneNumber: this.recoveryType === 'phone' ? this.recoveryPhone : null,
          email: this.recoveryType === 'email' ? this.recoveryEmail : null
        })

        this.sendRecoveryCodeLabel = 'Resend Recovery Code'
      } catch (err) {
        console.log(err)
        addAlert({ message: err, type: 'error' })
      } finally {
        this.isSubmitting = false
      }
    },
    cancelSignIn () {
      this.signInState = SIGN_IN_STATES.VERIFICATION
    },
    startRecovery () {
      this.signInState = SIGN_IN_STATES.RECOVER_ACCOUNT
      this.defaultRecoveryPhonePrefix = extractPhonePrefixFromNumber(this.phoneNumber, this.phoneCountryCodes)
    },
    cancelRecovery () {
      this.signInState = SIGN_IN_STATES.VERIFICATION
    },
    onSignInCancel () {
      this.signInState = SIGN_IN_STATES.VERIFICATION
      this.verificationCode = null
    }
  },
  beforeDestroy () {
    if (this.startSendCodeTimeoutHandle) {
      clearTimeout(this.startSendCodeTimeoutHandle)
    }
  }
}
