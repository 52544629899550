import './assets/fonts/stylesheet.css'
import './styles/site-styles.scss'
import store from './store'
import { D_GET_FROM_CACHE, D_SET_TO_CACHE } from './utilities/action-types'

async function initAndRefreshEulaSetting () {
  const eula = await store.dispatch(D_GET_FROM_CACHE, 'eula')
  if (eula === 'true') {
    store.dispatch(D_SET_TO_CACHE, {
      key: 'eula',
      value: 'true',
      expiryInSeconds: (30 * 24 * 60 * 60000) // 1 month
    })
  }
}

initAndRefreshEulaSetting()

export default {
  name: 'App',
  props: {
    locale: String
  },
  data () {
    return {
      title: 'Welcome to SmartTransit',
      currentLocale: this.$route.params.locale || process.env.VUE_APP_DEFAULT_LOCALE,
      pageHeightOffset: 100
    }
  },
  computed: {
    signedInUser () {
      return this.$store.getters.user || null
    }
  },
  mounted () {
    setTimeout(() => {
      this.onPageResize.bind(this)
    }, 800)
  },
  methods: {
    onPageResize () {
      if (this.$refs) {
        this.pageHeightOffset = (this.$refs.siteHeader.$el.clientHeight + this.$refs.siteFooter.$el.clientHeight)
      }
    }
  },
  beforeCreate () {
  },
  beforeDestroy () {
  }
}
