import Vue from 'vue'
import Vuex from 'vuex'
import cacheSettings from './store-modules/cache-settings'
import quickpay from './store-modules/quickpay'
import credentials from './store-modules/credentials'
import siteAlerts from './store-modules/site-alerts'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    cacheSettings,
    quickpay,
    credentials,
    siteAlerts
  }
})
