const LOCAL_STORAGE_PREFIX = 'stqp_'

function garbageCollectCache (cacheKey) {
  let data = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${cacheKey}`)
  data = JSON.parse(data)

  if (data.expiresTimestamp) {
    if (Date.now() > data.expiresTimestamp) {
      removeCache(cacheKey)
    }
  } else {
    return data
  }
}

function setCache (cacheKey, value, expiryInSeconds) {
  expiryInSeconds = expiryInSeconds === undefined ? 60 : expiryInSeconds // defaults to one minute

  if (value !== null && value !== undefined) {
    localStorage.setItem(`${LOCAL_STORAGE_PREFIX}${cacheKey}`, JSON.stringify({ expiresTimestamp: expiryInSeconds ? new Date(Date.now() + expiryInSeconds * 1000).getTime() : null, data: value }))
  } else {
    throw new Error('No value provided to cache')
  }
}

function getCache (cacheKey) {
  const result = garbageCollectCache(cacheKey)
  return result.data
}

function removeCache (cacheKey) {
  localStorage.setItem(`${LOCAL_STORAGE_PREFIX}${cacheKey}`, '')
  localStorage.removeItem(`${LOCAL_STORAGE_PREFIX}${cacheKey}`)
}

export {
  setCache,
  getCache,
  removeCache
}
